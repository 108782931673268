import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import axios  from 'axios';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/plugins/unicons';
import '@/core/components/custom';
import '@/core/components/style';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
window.ApiUrl = process.env.VUE_APP_API_ENDPOINT;

axios.interceptors.request.use(function(config) {
    store.commit('loading', true);
    config.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    return config;
}, (error) => {
    store.commit('loading', false);
    return Promise.reject(error);
});


app.config.globalProperties.$hostImage = process.env.MIX_API_URL;

app.config.productionTip = false;
app.use(store);
app.use(router);
app.mount('#app');
