export default {
  removeAllCart(state){
      state.cartList = [];
      state.shipping = {parcel : '',service : '',deliveryEstimate : '',totalPrice : 0};
      state.discount = 0;
      localStorage.setItem("cart", state.cartList);
  },
  addToCart(state, item) {
    console.log('esto llega a la mutacion addToCart --> ', item);
      var reItem = true;
      let found = state.cartList.find(product => product.productId == item.productId);
      
      if(found){
          if(item.limit >= item.productQuantity){
            console.log('si se agrega la cantidad de :  ', item.productQuantity + ' de ' + item.limit);
              found.productQuantity = item.productQuantity;
          }else{
              reItem = false;
              console.log('mp se pueden agregar mas de ', item.limit)
          }
      }else{
          if(item.limit >= item.productQuantity){
              state.cartList.push(item);
          }else{
              reItem = false;
          }
      }
      
      if(reItem){
          console.log('entra a guardar el commit --> ');
          this.commit('saveData');
        }else{
        console.log('No entra a guardar el commit --> ');
         console.log('error')
      }
  },

  saveData(state){
      window.localStorage.setItem('cart',JSON.stringify(state.cartList));
  },
  deleteCart(state,id){
    console.log('entra a eliminar producto de cart --> ', id);
    // let index = state.cartList.indexOf(item);
    state.cartList = state.cartList.filter((item) => item.productId !== id);
    console.log('esto encontro --> ', state.cartList );
      
      // state.cartList.splice(index,1);
      this.commit('saveData');
      if(state.cartCount == 0){
          state.coupon = {id:0,name:"",percentage:0};
          this.commit('saveDataCoupon');
      }
  },
  addCoupon(state, coupon){
      state.coupon = coupon;
    
      console.log('Cupón agregado');
      this.commit('saveDataCoupon');
  },
  saveDataCoupon(state){
      window.localStorage.setItem('coupon',JSON.stringify(state.coupon));
  },
  addShipping(state, shipping){
      state.shipping = shipping;
      
      this.commit('saveDataShipping');
  },
  saveDataShipping(state){
      window.localStorage.setItem('shipping',JSON.stringify(state.shipping));
  },



  singleProductBegin(state) {
    state.isLoading = true;
  },
  singleProductSuccess(state, data) {
    state.isLoading = false;
    state.product = data;
  },
  singleProductErr(state, err) {
    state.isLoading = false;
    state.error = err;
  },

  filterProductBegin(state) {
    console.log('entra a mutations');
    state.isProductLoading = true;
  },
  filterProductSuccess(state, data) {
    state.isProductLoading = false;
    console.log('entra a mutations filterProductSuccess -->', data);
    // state.products = data;
  },
  filterProductErr(state, err) {
    state.isProductLoading = false;
    state.error = err;
  },

  sortingProductBegin(state) {
    state.isProductLoading = true;
  },
  sortingProductSuccess(state, data) {
    state.isProductLoading = false;
    state.products = data;
  },
  sortingProductErr(state, err) {
    state.isProductLoading = false;
    state.error = err;
  },
};
