import staticData from '../../../../demoData/products.json';
import mutations from './mutations';
let cart = window.localStorage.getItem('cart');
let coupon = window.localStorage.getItem('coupon');
let shipping = window.localStorage.getItem('shipping');

const state = () => ({
 
    cartList: cart ? JSON.parse(cart) : [],
    cartCount:0,
    totalCart: 0,
    subTotalCart: 0,
    coupon:coupon ? JSON.parse(coupon) : {id:0,name:"",percentage:0},
    discount:0,
    shipping: shipping ? JSON.parse(shipping) : {parcel : '',service : '',deliveryEstimate : '',totalPrice : 0}

});


const actions = {
  async filterSinglePage({ commit }, { paramsId, currentState }) {
    try {
      commit('singleProductBegin');
      const data = currentState.filter((product) => {
        return product.id === paramsId;
      });
      commit('singleProductSuccess', data);
    } catch (err) {
      commit('singleProductErr', err);
    }
  },

  async sorting({ commit }, sortBy) {
    try {
      commit('sortingProductBegin');
      setTimeout(() => {
        const data = staticData.sort((a, b) => {
          return b[sortBy] - a[sortBy];
        });
        commit('sortingProductSuccess', data);
      }, 100);
    } catch (err) {
      commit('sortingProductErr', err);
    }
  },

  async filterByPriceRange({ commit }, range) {
    commit('filterProductBegin');
    try {
      const data = staticData.filter((product) => {
        return product.price >= range[0] && product.price <= range[1];
      });
      commit('filterProductSuccess', data);
    } catch (err) {
      commit('filterProductErr', err);
    }
  },

  async filterByRating({ commit }, range) {
    try {
      commit('filterProductBegin');
      setTimeout(() => {
        const data = staticData.filter((product) => {
          if (range[0].length) {
            return range[0].includes(product.rate);
          }
          return staticData;
        });
        commit('filterProductSuccess', data);
      }, 100);
    } catch (err) {
      commit('filterProductErr', err);
    }
  },

  async filterByBrand({ commit }, brand) {
    try {
      commit('filterProductBegin');
      setTimeout(() => {
        const data = staticData.filter((product) => {
          if (brand[0].length) {
            return brand[0].includes(product.brand);
          }
          return staticData;
        });
        commit('filterProductSuccess', data);
      }, 100);
    } catch (err) {
      commit('filterProductErr', err);
    }
  },

  async filterByCategory({ commit }, category) {
    try {
      commit('filterProductBegin');
      setTimeout(() => {
        const data = staticData.filter((product) => {
          if (category !== 'all') {
            return product.category === category;
          }
          return staticData;
        });
        commit('filterProductSuccess', data);
      }, 100);
    } catch (err) {
      commit('filterProductErr', err);
    }
  },

  async updateWishList({ commit }, id) {
    console.log('entra a vuex updateWishList value -->', id);
    
    try {
      commit('filterProductBegin');

      staticData.map((product) => {
        if (product.id === id) {
          return product.popular ? (product.popular = false) : (product.popular = true);
        }
        return commit('filterProductSuccess', staticData);
      });
    } catch (err) {
      commit('filterProductErr', err);
    }
  },
};


export default {
  namespace: true,
  state,
  getters: {
    cartList: state => state.cartList,
    coupon1: state => state.coupon,
    shipping: state => state.shipping,
    cartCount: state => state.cartList.length,
    discount: state => {
        //state.discount = 0;
        state.discount = (state.totalCart * (state.coupon.percentage / 100)).toFixed(2);
        return state.discount;
        //return (state.discount).toFixed(2);
    },
    subTotalCart: state => {
        state.subTotalCart = 0;
        state.cartList.forEach((element) => {
            state.subTotalCart += element.productPrice.replace(',','') * element.productQuantity;
        })
        return ((state.subTotalCart)+state.shipping.totalPrice).toFixed(2);
    },
    totalCart: state => {
        state.totalCart = 0;
        state.cartList.forEach((element) => {
            state.totalCart += element.productPrice.replace(',','') * element.productQuantity;
        })
        return ((state.totalCart-state.discount)+state.shipping.totalPrice).toFixed(2);
    },
    
  },
  actions,
  mutations,
};
