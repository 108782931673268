import mutations from './mutations';
import Cookies from 'js-cookie';
// import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';
import axios from "axios";


const addNotificationSuccess = () => {
  notification.success({
    message: '¡Bienvenido!',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};


const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
});

const actions = {
  async login({ commit }, data) {    
    
         
      await axios.post('/account/login', data)
      .then(function (response) {
        if(response.data.message == "success"){
          if(response.data.access_token !== undefined && response.data.access_token !== null){
            addNotificationSuccess();
            Cookies.set('logedIn', true);
            localStorage.setItem("authToken", response.data.access_token);
             commit('loginBegin'); 
            return  commit('loginSuccess', response.data);
          }else{
            Cookies.set('logedIn', false); 
            addNotificationError(response.data.errors);
            return   commit('loginErr', response.data.errors);
          }
        }else if(response.message == "error"){
          addNotificationError(response.data);
          return commit('loginErr', response.data.errors);
        }
      })
      .catch(function (error) {
        commit('loginErr', error.response.data);
        addNotificationError('usuario y contraseña invalidos');
      });
    
      // localStorage.setItem("authToken", response.data.access_token);
     
  },

  // async login({ commit }) {
  //   try {
  //     commit('loginBegin');
  //     Cookies.set('logedIn', true);
  //     return commit('loginSuccess', true);
  //   } catch (err) {
  //     commit('loginErr', err);
  //   }
  // },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      Cookies.remove('logedIn');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
